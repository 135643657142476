<template>
  <v-row class="pb-10">
    <!-- header -->
    <mini-header :list="status" :filter.sync="filter" title="Documentos recibidos" :breadcrumbs="breadcrumbs" :hiddenTab="!$helpers.hasSomePermission(['view_purchase'])" :scroll="scroll">
      <template v-slot:main>
        <v-btn @click="dialog=true" color="primary" :ripple="false"><v-icon left>mdi-plus-box</v-icon>Crear automatización</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
     <v-row class="mt-9" v-if="loadingRules">
      <v-col class="pa-2" v-for="item in 4" :key="item">
        <v-skeleton-loader class="background mb-5 rounded-md" type="image" />
      </v-col>
    </v-row>
    <v-col class="px-0 mt-9" v-else>
      <v-empty-state class="mt-5" v-if="!groupsRules?.length" type="automation" id="Automatización" customClass="mt-8" :hiddenDescription="true" :isFree="true" title="No existen automatizaciones para mostrar">
        <template v-slot:actions>
          <v-btn class="ml-2" @click="dialog=true" color="primary" :ripple="false"><v-icon class="mr-2">mdi-plus-box</v-icon>Crear automatización</v-btn>
        </template>
      </v-empty-state>
      <v-row v-else>
        <v-col :cols="$vuetify.breakpoint.width >= 1280 ? 3 : 4" class="pa-2" v-for="(group, index) in groupsRules" :key="index">
          <v-card flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-4 subtitle-2 secondary--text font-weight-semibold">
               <v-list-item-title v-if="group.name" class="subtitle-2 secondary--text font-weight-semibold mb-1">{{ group.name }}</v-list-item-title>
               <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-row align="center" justify="center" class="mr-3">
                  <v-btn @click="handlerEdit(group)" icon small retain-focus-on-click><v-icon color="secondary" size="20">mdi-pencil</v-icon></v-btn>
                </v-row>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pt-0">
              <div class="mt-5" v-if="!group.is_active">
                <v-chip class="font-weight-semibold" color="greyMedium" small label>Desactivada<v-icon right>mdi-cancel</v-icon></v-chip>
              </div>
              <div class="d-flex" v-else>
                <div>
                  <span class="d-block caption fontBody--text">Se</span>
                  <v-chip class="font-weight-semibold" :color="statusChip[group.action?.toLowerCase()]?.color" small label>{{ statusChip[group.action?.toLowerCase()]?.key }}<v-icon right>mdi-{{statusChip[group.action?.toLowerCase()]?.icon}}</v-icon></v-chip>
                </div>
                <div class="ml-3" v-if="group.if_not">
                  <span class="d-block caption fontBody--text">Si no</span>
                  <v-chip class="font-weight-semibold" :color="statusChip[group.if_not?.toLowerCase()]?.color" small label>{{ statusChip[group.if_not?.toLowerCase()]?.key }}<v-icon right>mdi-{{statusChip[group.if_not?.toLowerCase()]?.icon}}</v-icon></v-chip>
                </div>
              </div>
              <div class="mt-5 d-flex justify-space-between body-2">
                <div class="fontBody--text font-weight-medium">
                  Grupo:
                </div>
                <div :class="group.quantity_supps ? 'pr-7' : 'pr-5'">
                  <span class="grey-300--text font-weight-medium" v-if="group.quantity_supps">{{ group.quantity_supps  }} {{ group.quantity_supps > 1 ? 'grupos' : 'grupo' }}</span>
                  <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  <v-spacer />
                </div>
              </div>
              <div class="mt-2 d-flex justify-space-between body-2">
                <div class="fontBody--text">
                  Fecha de act.
                </div>
                <div class="grey-300--text">
                  {{ group.updated | dateTimeSecond }}
                </div>
              </div>
              <div class="mt-2 d-flex justify-space-between body-2">
                <div class="fontBody--text">
                  Fecha de crea.
                </div>
                <div class="grey-300--text">
                  {{ group.created | dateTimeSecond }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <VMainPagination :count="groupsRules?.length" />
    </v-col>

    <!-- dialog create/edit-->
    <v-dialog v-model="dialog" fullscreen persistent :scrollable="true" no-click-animation overlay-color="overlay" :key="resetDialog"
    >
      <v-card class="white" :img="require(`@/assets/automation/background-modal.svg`)">
        <v-card-title class="pa-0 white">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Reglas de automatización</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="clear(), dialog=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
          <v-card-text class="py-4 px-5 text-center fontBody--text">
            <v-col cols="9" class="mx-auto my-5">
              <v-card class="background d-block" flat>
                <v-card-text>
                  <div class="d-flex align-center">
                    <div class="subtitle-2 fontBody--text font-weight-semibold">Nombre de la automatización</div>
                    <v-col class="pa-0 pl-5">
                      <v-text-field v-model="$v.name.$model" :error="$v.name.$error" outlined single-line dense hide-details />
                    </v-col>
                  </div>
                </v-card-text>
              </v-card>
              <v-divider class="divider" vertical />
              <div class="mt-n1">
                <v-chip class="py-6 px-5 font-weight-semibold rounded-pill" color="lightBlue">
                  <span class="mr-2">Si</span>
                  <v-chip class="mr-2 pl-1 pr-0" color="white">
                    <v-chip class="mr-2 px-3" :color="currentRule.operator === 'Y' ? 'primary' : 'white'" :text-color="currentRule.operator !== 'Y' ?'primary' : 'white'" small @click="currentRule.operator = 'Y'">todas</v-chip>
                    <v-chip class="mr-2 px-3" :color="currentRule.operator === 'O' ? 'primary' : 'white'" :text-color="currentRule.operator === 'Y' ?'primary' : 'white'" small @click="currentRule.operator = 'O'">algunas</v-chip>
                  </v-chip>
                  {{ currentRule.operator === 'Y' ? 'las siguientes reglas se cumplen' : 'de las siguientes reglas se cumplen' }}
                </v-chip>
              </div>
              <v-divider class="divider" vertical />
              <v-card class="mt-n1 background d-block" flat>
                <v-card-text class="pt-8 pb-2">
                  <v-row class="pb-3" align="center" v-for="(r, i) in rules" :key="i" no-gutters>
                    <v-col class="pa-0 pl-2">
                      <v-autocomplete
                        v-model="$v.rules.$each[i].attributeModel.$model"
                        :error="$v.rules.$each[i].attributeModel.$error"
                        :items="attribute"
                        :menu-props="{ overflowY: true, transition: 'slide-y-transition' }"
                        height="30"
                        single-line
                        outlined
                        dense
                        hide-details
                        @input="r.comparisonOperatorsModel = null"
                      />
                    </v-col>
                    <v-col class="pa-0 pl-2">
                      <v-autocomplete
                        v-model="$v.rules.$each[i].comparisonOperatorsModel.$model"
                        :error="$v.rules.$each[i].comparisonOperatorsModel.$error"
                        :items="comparisonOperators[r.attributeModel]?.list || []"
                        :menu-props="{ overflowY: true, transition: 'slide-y-transition' }"
                        height="30"
                        single-line
                        outlined
                        dense
                        hide-details
                        :disabled="!r.attributeModel"
                      />
                    </v-col>
                    <v-col class="pa-0 pl-2">
                      <template  v-if="comparisonOperators[r.attributeModel]?.input === 'number'">
                      <v-text-field
                        v-if="r.comparisonOperatorsModel !== 'Entre'"
                        v-model="$v.rules.$each[i].value.$model"
                        :error="$v.rules.$each[i].value.$error"
                        outlined
                        single-line
                        dense hide-details
                        id="number"
                        :disabled="!r.comparisonOperatorsModel"
                      />
                      <div class="d-flex" v-else>
                        <div class="mr-2">
                          <v-text-field
                            v-model="$v.rules.$each[i].value.$model"
                            :error="$v.rules.$each[i].value.$error"
                            outlined
                            single-line
                            dense hide-details
                            prefix="desde"
                            id="number"
                            :disabled="!r.comparisonOperatorsModel"
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="$v.rules.$each[i].value2.$model"
                            :error="$v.rules.$each[i].value2.$error"
                            outlined
                            single-line
                            dense hide-details
                            prefix="hasta"
                            id="number"
                            :disabled="!r.comparisonOperatorsModel"
                          />
                        </div>
                      </div>
                      </template>
                      <v-autocomplete
                        v-else-if="comparisonOperators[r.attributeModel]?.input === 'autocomplete'"
                        v-model="$v.rules.$each[i].value.$model"
                        :error="$v.rules.$each[i].value.$error"
                        :items="comparisonOperators[r.attributeModel]?.inputList"
                        :menu-props="{ overflowY: true, transition: 'slide-y-transition', 'max-width': 400 }"
                        height="30"
                        single-line
                        auto-select-first
                        outlined
                        dense
                        hide-details
                        chip
                        @focus="comparisonOperators[r.attributeModel].inputList = sortedItems(comparisonOperators[r.attributeModel]?.inputList ?? [], r.value)"
                        :multiple="r.attributeModel === 'Giro del receptor'"
                        :disabled="!r.comparisonOperatorsModel"
                        :prefix="r.value?.length >= 1 ? `${(r.value?.length === 1 && typeof r.value === 'object' ) || typeof r.value === 'string' ? `${typeof r.value === 'string' ? '1' : r.value?.length} seleccionado` : `${r.value?.length} seleccionados`}` : ''"
                      >
                        <template v-slot:selection="data">
                          <span v-if="data"></span>
                        </template>
                      </v-autocomplete>
                      <v-text-field v-else outlined single-line dense hide-details :disabled="!r.comparisonOperatorsModel" />
                    </v-col>
                    <v-col cols="1" style="max-width: 30px">
                      <v-btn v-if="i !== 0" text color="greyMedium" @click="rules.splice(i, 1)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-col class="text-left py-1">
                    <v-btn class="font-weight-medium body-2" text color="primary" @click="addRow">+ Agregar otro</v-btn>
                  </v-col>
                </v-card-text>
              </v-card>
              <v-divider class="divider" vertical />
              <template v-if="$store?.state?.auth?.account?.suppliergroup_feature && $helpers.hasSomePermission(['view_suppliergroup'])">
              <div class="mt-n1">
                <v-chip class="px-8 py-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">Para</v-chip>
              </div>
              <v-divider class="divider" vertical />
                <v-card v-if="dialog" class="background d-block" flat>
                  <v-card-text class="py-5 px-2">
                    <v-col class="d-flex align-center">
                      <div class="subtitle-2 fontBody--text font-weight-semibold">El grupo o los grupos</div>
                      <v-col cols="5" class="ml-2">
                        <v-autocomplete
                          v-model="currentRule.suppliers_group"
                          hide-details
                          single-line
                          color="primary"
                          dense
                          outlined
                          :items="groupsList"
                          multiple
                          :chips="false"
                          item-text="name"
                          item-value="id"
                          item-disabled="customDisabled"
                          @focus="groupsList = sortedItems(groupsList, currentRule.suppliers_group)"
                          :prefix="currentRule?.suppliers_group?.length >= 1 ? `${currentRule?.suppliers_group?.length === 1 ? `${currentRule?.suppliers_group?.length} seleccionado` : `${currentRule?.suppliers_group?.length} seleccionados`}` : ''"
                          no-data-text="No existen miembros para mostrar"
                        >
                          <template v-slot:selection="{ props }">
                            <span v-if="props" style=""></span>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-col>
                    <v-row class="mt-n3" no-gutters>
                      <v-col cols="3" class="pt-0"></v-col>
                      <v-col cols="" class="pa-0 ml-n13 text-left">
                        <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(group, i) in currentRule?.suppliers_group" :key="`${group}${i}`" close @click:close="groupRemove(group, i)" color="lightBlue" small label close-icon="mdi-close">
                          {{ groupsList.find(({id}) => id == group)?.name ?? group }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-divider class="divider" vertical />
              </template>
              <div class="mt-n1">
                <v-chip class="px-8 py-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">Entonces</v-chip>
              </div>
              <v-divider class="divider" vertical />
              <!-- action-->
              <ActionType
                :model.sync="modelAction"
                :rule="{ color: documentOptions.find(({id}) => id === modelAction.value)?.color, value: currentRule, type: currentRule?.action ?? 'accept', items: documentOptions.slice(0,2)}"
                key="action"
                />
              <!-- end action-->
              <!-- config action-->
              <v-divider class="divider" vertical />
              <Configurations
                :isActive.sync="panelConfigThen"
                :rule="{...currentRule, type: 'Entonces', mode}"
                :model.sync="modelConfigThen"
                key="then"
              />
              <!-- end config action-->
              <v-divider class="divider" vertical />
              <div>
                <v-chip class="px-8 py-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">Sino</v-chip>
              </div>
              <v-divider class="divider" vertical />
              <!--- if not -->
              <ActionType
                :model.sync="modelIfNot"
                :rule="{ color: documentOptions.find(({id}) => id === modelIfNot.value)?.color, value: currentRule, type: currentRule?.if_not, items: documentOptions.filter(({id}) => id !== modelAction.value ) }"
                key="ifNot"
              />
              <!-- end if not -->
              <!-- config if not-->
              <v-divider class="divider" vertical />
              <Configurations
                :isActive.sync="panelConfigIfNo"
                :rule="{...currentRule, type: 'Sino', mode}"
                :model.sync="modelConfigIfNot"
              />
              <!-- end config if not-->
              <v-divider class="divider" vertical />
              <div>
                <v-chip class="px-8 py-6 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">
                  Excepto
                  <v-chip class="ml-3 px-3 py-0 font-weight-light" small color="white">Opcional</v-chip>
                </v-chip>
              </div>
              <v-divider class="divider" vertical />
              <!-- excepto -->
              <v-card class="d-block background" flat>
                <v-card-text class="px-5`">
                  <v-row no-gutters class="ma-0">
                    <v-col cols="4">
                      <v-text-field v-model="exception.name" outlined single-line dense hide-details readonly />
                    </v-col>
                      <v-col class="d-flex align-center px-2" cols="4">
                        <v-select
                          v-model="exception.operator"
                          :items="comparisonOperators['Monto neto'].list"
                          height="30"
                          item-text="text"
                          item-value="id"
                          single-line
                          outlined
                          dense
                          hide-details
                        />
                    </v-col>
                    <v-col cols="4">
                      <div class="d-flex" v-if="exception.operator === 'Entre'">
                        <div class="mr-2">
                          <v-text-field
                            v-model="exception.value"
                            outlined
                            single-line
                            dense hide-details
                            prefix="desde"
                            id="number"
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="exception.value2"
                            outlined
                            single-line
                            dense hide-details
                            prefix="hasta"
                            id="number"
                          />
                        </div>
                      </div>
                      <v-text-field v-else v-model="exception.values" outlined single-line dense hide-details />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- end excepto -->
              <v-divider class="divider" vertical />
              <div>
                <v-chip class="px-8 py-6 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">
                  Por lo que
                </v-chip>
              </div>
              <v-divider class="divider" vertical />
              <v-card class="yellowLight d-block" flat>
                <v-card-text class="px-5 py-0">
                  <v-row no-gutters class="ma-0">
                    <v-col class="d-flex align-center font-weight-semibold justify-center pa-0" style="position: relative; left: 100px;">
                      <span class="fontBody--text">El documento se mantiene igual</span>
                    </v-col>
                      <img class="ma-0" :src="require(`@/assets/automation/nothing.svg`)" :height="100" />
                  </v-row>
                </v-card-text>
              </v-card>
              <!--- end por lo que-->
              <!-- config excepto-->
              <v-divider class="divider" vertical />
              <Configurations
                :isActive.sync="panelConfigExcept"
                :rule="{...currentRule, type: 'Excepto', mode}"
                :model.sync="modelConfigExcept"
              />
              <!-- end config excepto-->
            </v-col>
          </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5 white">
          <template v-if="mode === 'edit'">
            <v-btn @click="dialogDelete = true" color="error" text><v-icon size="20">mdi-delete</v-icon>Eliminar automatización</v-btn>
            <v-divider class="mx-5" vertical />
            <v-switch class="pa-0 ma-0" v-model="currentRule.is_active" @change="dialogDisable=true" :ripple="false" flat label="Desactivar automatización" color="success" hide-details></v-switch>
            <v-spacer />
            <v-btn @click="updateRule" :loading="loadingSave" color="primary">Guardar cambios</v-btn>
          </template>
          <template v-else>
            <v-btn @click="clear(), dialog=false" color="primary" text>Cancelar</v-btn>
            <v-spacer />
            <v-btn @click="createRule('SaveAddOther')" outlined :loading="loadingSaveAddOther">Guardar y agregar otra</v-btn>
            <v-btn @click="createRule('Save')" :loading="loadingSave" color="primary">Guardar</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create/edit -->

    <!-- dialog confirm delete -->
    <v-dialog v-model="dialogDelete" width="759" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">Eliminar automatización</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false, confirmar=''" icon small retain-focus-on-click><v-icon color="secondary" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 secondary--text">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-delete-auto.svg`)" :width="168" />
            </v-col>
            <v-col class="pl-6 pr-0">
              <span class="d-block">Al eliminar esta automatización, los grupos asociados perderán todas las reglas aplicadas actualmente. Como resultado, los documentos recibidos de estos grupos deberán ser revisados manualmente.</span>
              <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false, confirmar=''" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroyGroup" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog confirm desactivar -->
    <v-dialog v-model="dialogDisable" width="759" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">Activar automatización</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDisable=false, confirmar=''" icon small retain-focus-on-click><v-icon color="secondary" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 secondary--text">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-delete-auto.svg`)" :width="168" />
            </v-col>
            <v-col class="pl-6 pr-0">
              <span class="d-block">Al activarla, los documentos recibidos se evaluarán según el conjunto de reglas asociado a esta automatización. Puedes desactivarla cuando lo desees.</span>
              <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDisable=false, confirmar=''" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="toggleGroupStatus" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm desactivar -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import ListViewMixin from '@/mixins/ListViewMixin'
import activitiesSIIList from '@/collections/activitiesSIIList'
import VMainPagination from '@/components/commons/VMainPagination'
import ActionType from '@/modules/purchase/automation/components/ActionType'
import Configurations from '@/modules/purchase/automation/components/Configurations'
addEventListener("keypress", (e) => {
  if (e.target.id === 'number') {
    if (e.keyCode < 48 || e.keyCode > 57) {
      e.preventDefault()
    }
  }
})
export default {
  components: {
    VEmptyState,
    MiniHeader,
    VMainPagination,
    ActionType,
    Configurations
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    resetDialog: 0,
    confirm: '',
    dialogDisable: false,
    dialogDelete: false,
    panelConfigIfNo: 1,
    panelConfigThen: 1,
    panelConfigExcept: 1,
    resetTab: 0,
    modelIfNot: {
      value: 'reject'
    },
    modelConfigIfNot: {
      emails: [],
      send_mail: null,
      sftp: null,
      stage: ''
    },
    modelConfigThen: {
      emails: [],
      send_mail: null,
      sftp: null,
      stage: ''
    },
    modelConfigExcept: {
      emails: [],
      send_mail: null,
      sftp: null,
      stage: ''
    },
    modelAction: {
      value: 'accept'
    },
    count: 0,
    filter: 'Todos',
    scroll: 0,
    loadingSave: false,
    loadingSaveAddOther: false,
    loadingRules: false,
    groupsRules: [],
    mode: 'create',
    currentRule: {
      operator: 'Y',
      suppliers_group: [],
      type: 'accept',
      send_mail: false,
      is_active: false,
      if_not: 'reject',
      exchange_status: null,
      days: 0
    },
    name: '',
    rules: [
      {
        comparisonOperatorsModel: null,
        attributeModel: null,
        value: null,
        value2: null
      }
    ],
    exception: {
      operator: null,
      name: 'Monto neto',
      value: null,
      value3: null,
      exception: true
    },
    activeWebhook: false,
    activeSftp: false,
    dialog: false,
    breadcrumbs: {
     main: 'Recepción',
     children: [
      {
        text: 'Automatización'
      }
     ]
   },
   attribute: ['Fecha de emisión', 'Fecha de recepción', 'Referencia - Tipo de documento', 'Referencia - Número', 'Monto total', 'Monto neto', 'Giro del receptor', 'Fecha de recepción SII'],
   comparisonOperators: {
    'Fecha de emisión': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Fecha de recepción': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Referencia - Tipo de documento': {
      list: ['Es igual a'],
      input: 'autocomplete',
      inputList: ['OC', 'HES', 'Contrato', 'DUS', 'AWB', 'MIC/DTA', 'Ficha ChileCompra', 'Proceso ChileCompra', 'Resolución', 'Nota de pedido']
    },
    'Referencia - Número': {
      list: ['Es igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Monto total': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Monto neto': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
     'Giro del receptor': {
       list: ['Uno de'],
       input: 'autocomplete',
       inputList: [...activitiesSIIList.map(({name}) => name)]
    },
    'Fecha de recepción SII': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    }
   },
   documentModel: 'accept',
   documentOptions: [
    {
      id: 'accept',
      text: 'Se acepta',
      img: 'create',
      color: 'lightBlue'
    },
    {
      id: 'reject',
      text: 'Se rechaza',
      img: 'refused',
      color: 'redLight'
    },
    {
      id: 'nothing',
      text: 'Se mantiene igual',
      img: 'equall',
      color: 'yellowLight'
    }
    ],
    status: [
      {
        key: 'Acepta',
        value: 'ACCEPT',
        icon: 'mdi-file-check'
      },
      {
        key: 'Reclama',
        value: 'REJECT',
        icon: 'mdi-file-remove'
      }
      // {
      //   key: 'Desactivada',
      //   value: '',
      //   icon: 'mdi-cancel'
      // }
    ],
    statusChip: {
      accept: {
        key: 'Acepta',
        color: 'lightBlue',
        icon: 'file-check'
      },
      reject: {
        key: 'Reclama',
        color: 'redLight',
        icon: 'file-remove'
      },
      nothing: {
        key: 'Mantiene igual',
        color: 'yellowLight',
        icon: 'file-clock'
      },
      '': {
        key: 'Desactivada',
        color: 'greyMedium',
        icon: 'cancel'
      }
    }
  }),
  computed: {
    ...mapState({
      membersList: state => state.accounts.membersList,
      groups: state => state.suppliers.list_automatizationList,
      rules_notificationList: state => state.purchases.rules_notificationList
    }),
    sortedItems () {
      return (list = [], value = null) => {
        const selected = list.filter(item => value?.includes(item?.id ?? item))
        const notSelected = list.filter(item => !value?.includes(item?.id ?? item))
        return [...selected, ...notSelected]
      }
    }
  },
  watch: {
    modelAction: {
      handler (val) {
        this.modelIfNot.value = this.documentOptions.filter(({id}) => id !== val.value)[0].id
      },
      inmediate: true,
      deep: true
    },
    dialog (val) {
      if (val) {
        this.$store.dispatch('accounts/LIST', {
          resource: 'members',
          query: {
            page_size: 9999,
            is_active: true
          }
        })
      }
    },
    filter (val) {
    let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
     if (val > 0) {
       delete currentQuery.page
        let query = this.status[val - 1].value
        this.$router.replace({name: this.$route.name, query: {...currentQuery, action: query} }).catch(() => {})
      } else {
        delete currentQuery.action
        this.$router.replace({ name: this.$route.name, query: currentQuery }).catch(() => { })
     }
   }
  },
  created () {
    this.getList()
    if (this.$store?.state?.auth?.account?.suppliergroup_feature && this.$helpers.hasSomePermission(['view_suppliergroup'])) {
      this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/groups/list_automatization',
        query: {
          page_size: 9999
        }
      })
      .then(() => {
        this.groupsList = this.groups
      })
    }
  },
  methods: {
    /* eslint-disable */
    handlerEdit(group = {}) {
      this.resetDialog += 1
      this.mode = 'edit'
      if (this.mode === 'edit') {
        this.$store.dispatch('purchases/LIST', {
          resource: 'rules_notification',
          query: {group_rules: group.id}
        })
        this.currentRule = {
        id: group.id,
        is_active: !group.is_active,
        operator: group.operator,
        action: group.action?.toLowerCase(),
        if_not: group.if_not?.toLowerCase(),
        exchange_status: group.exchange_status,
        days: group.days,
          suppliers_group: [...group.suppliers_group],
        }
        this.name = group?.name ?? ''
        this.rules = group.rules.filter((item) => !item?.exception).map(({ name, operator, values, list_values }) => {
          let v = null
          if (operator === 'Entre') v = values?.replace(/\[|\]/g, '')?.split(',')

          return {
            comparisonOperatorsModel: operator,
            attributeModel: name,
            value: operator === 'Entre' ? v[0] : values ?? list_values,
            ...(operator === 'Entre' && { value2: v[1]}) 
          }
        })
        let ruleExeption = cloneDeep(group.rules.find((item) => item?.exception)) ?? { operator: null, name: 'Monto neto', value: null, value2: null, exception: true }
        let vException = null
       if (ruleExeption.operator === 'Entre') vException = ruleExeption?.values?.replace(/\[|\]/g, '')?.split(',')
        this.exception = {
          ...ruleExeption,
          value: ruleExeption.operator === 'Entre' ? vException[0] : ruleExeption.value,
          ...(ruleExeption.operator === 'Entre' && { value2: vException[1]}) 
        }
      }
      this.dialog = true
    },
    addRow () {
      this.rules.push({
        comparisonOperatorsModel: null,
        attributeModel: null,
        value: null
      })
    },
    groupRemove (item) {
      const index = this.currentRule.suppliers_group.indexOf(item)
      if (index >= 0) this.currentRule.suppliers_group.splice(index, 1)
    },
    getList () {
      this.loadingRules = true
      this.$store.dispatch('purchases/LIST', {
        resource: 'groups_rules_reception',
        query: this.$route.query
      })
      .then((resp) => {
        this.groupsRules = resp.data?.results ?? []
        this.count = !!resp?.data?.next
      })
      .finally(() => {
        this.loadingRules = false
      })
    },
    setPayloadEditCreate() {
      let currentRules = []
      currentRules = this.rules.map(({attributeModel, comparisonOperatorsModel, value, value2}) => {
        if (attributeModel === 'Giro del receptor') {
          return {
            name: attributeModel,
            operator: comparisonOperatorsModel,
            list_values: value
          }
        } else {
          return {
            name: attributeModel,
            operator: comparisonOperatorsModel,
            values: comparisonOperatorsModel === 'Entre' ? `[${value},${value2}]` : value
          }
        }
      })
      let exceptionRule = { ...this.exception }
      exceptionRule = {
        ...exceptionRule,
        values: exceptionRule.operator === 'Entre' ? `[${exceptionRule.value},${exceptionRule.value2}]` : exceptionRule.value
      }
      delete exceptionRule.value2
      let payload = {}
      payload = {
        name: this.name,
        suppliers_group: this.currentRule.suppliers_group,
        operator: this.currentRule.operator,
        action: this.modelAction.value.toUpperCase(),
        if_not: this.modelIfNot.value.toUpperCase(),
        ...this.modelAction,
        ...this.modelIfNot,
        rules: exceptionRule?.values ? [...currentRules, exceptionRule] : [...currentRules]
      }

      delete payload.value
      return payload
    },
    updateRule() {
      this.$v.rules.$touch()
      this.$v.name.$touch()
      if (this.$v.rules.$invalid || this.$v.name.$invalid) return false
      this.$store.dispatch('purchases/UPDATE', {
        resource: 'groups_rules_reception',
        id: this.currentRule.id,
        payload: this.setPayloadEditCreate()
      })
      .then(async () => {
        await this.setRulesNotifications()
        this.$dialog.message.info('La automatización ha sido actualizada con éxito.')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loading = false
        this.dialog = false
        this.clear()
      })
    },
    async setRulesNotifications() {
      let list = []
      list = [this.modelConfigThen, this.modelConfigIfNot, this.modelConfigExcept]
      if (list?.length) {
        await Promise.all(list.map(async (notification) => {
          this.$store.dispatch(`purchases/${!notification.id ? 'CREATE' : 'UPDATE'}`, {
            resource: 'rules_notification',
            ...(notification.id && { id: notification.id }),
            payload: { ...notification, group_rules: this.currentRule.id }
          })
        }))
      }
    },
    createRule(action = 'Save') {
      this.$v.rules.$touch()
      this.$v.name.$touch()
      if (this.$v.rules.$invalid || this.$v.name.$invalid) return false

      this[`loading${action}`] = true

      this.$store.dispatch('purchases/CREATE', {
        resource: 'groups_rules_reception',
        payload: this.setPayloadEditCreate()
      })
      .then(async () => {
        await this.setRulesNotifications()
        this.$dialog.message.info('La automatización ha sido creada con éxito.')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        if (action === 'Save') {
          this.dialog = false
        }
        this[`loading${action}`] = false
        this.clear()
      })
    },
    destroyGroup () {
      this.$store.dispatch('purchases/DESTROY', {
        resource: 'groups_rules_reception',
        id: this.currentRule.id
      })
      .then(() => {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        if (currentQuery.page) delete currentQuery.page
        this.$router.replace({ query: {...currentQuery} }).catch(() => {})
        this.$dialog.message.info('La automatización se ha sido eliminado con éxito')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogDelete = false
        this.dialog = false
        this.clear()
      })
    },
    toggleGroupStatus () {
      this.$store.dispatch('purchases/GET', {
        resource: `groups_rules_reception/${this.currentRule.id}/change_status`
      })
      .then(() => {
        this.getList()
        this.$dialog.message.info(`La automatización se ha ${!this.currentRule.is_active ? 'activado' : 'desactivado'} con éxito`)
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogDisable = false
        this.dialog = false
        this.clear()
      })
    },
    clear () {
      this.currentRule = {
        operator: 'Y',
        suppliers_group: [],
        type: 'accept',
        send_mail: false,
        is_active: false,
        if_not: 'reject',
        exchange_status: null,
        days: 0
      }
      this.name = ''
      this.rules = [
        {
          comparisonOperatorsModel: null,
          attributeModel: null,
          value: null
        }
      ]
      this.exception = {
        operator: null,
        name: 'Monto neto',
        values: null,
        exception: true
      }
      this.modelIfNot = {
        value: 'reject',
        exchange_status: null,
        days: 0
      }
      this.modelAction = {
        value: 'accept',
        exchange_status: null,
        days: 0
      }
      this.$v.rules.$reset()
      this.$v.name.$reset()
      this.mode = 'create'
      this.confirm = ''
      this.panelConfigIfNo = 1
      this.panelConfigThen = 1
      this.panelConfigExcept = 1
      this.modelConfigIfNot = {
        emails: [],
        send_mail: null,
        sftp: null,
        stage: ''
      }
      this.modelConfigThen = {
        emails: [],
        send_mail: null,
        sftp: null,
        stage: ''
      }
      this.modelConfigExcept = {
        emails: [],
        send_mail: null,
        sftp: null,
        stage: ''
      }
      this.resetDialog +=1
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(80)
    },
    rules: {
      $each: {
        comparisonOperatorsModel: { required },
        attributeModel: { required },
        value: { required },
        value2: { 
          required: requiredIf(function (val) {
              return val.comparisonOperatorsModel === 'Entre'
            })
         }       
      }
    }
  }
}
</script>
<style>
  .input-file-automation.v-text-field, .vti__input {
    background: transparent
  }

  .input-file-automation.v-file-input .v-file-input__text {
    width: auto;
    margin-top: 10px;
  }

  .input-file-automation .v-icon__svg {
    height: 20px !important;
    width: 20px;
  }

  .divider {
    height: 20px;
    border-width:1px;
  }
</style>